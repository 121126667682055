<template>
  <div>
    <b-card>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="요청번호를 넣어주세요" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
      </b-input-group>
      <shop-preset class="mb-2" v-model="form.shop"></shop-preset>
      <b-row class="mb-2">
        <b-col cols="4">
          <small class="bold">요청 일시</small>
          <b-form class="mt-2" inline>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.requestDate.from" placeholder="2020-04-01 00:00:00" v-mask="'####-##-## ##:##:##'"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.requestDate.to" placeholder="2020-04-01 00:00:00" v-mask="'####-##-## ##:##:##'"></b-form-input>
            </b-form>
          </b-form>
        </b-col>
        <b-col cols="4">
          <small class="bold">입금 마감 일시</small>
          <b-form class="mt-2" inline>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.deadline.from" placeholder="2020-04-01 00:00:00" v-mask="'####-##-## ##:##:##'"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.deadline.to" placeholder="2020-04-01 00:00:00" v-mask="'####-##-## ##:##:##'"></b-form-input>
            </b-form>
          </b-form>
        </b-col>
        <b-col cols="4">
          <small class="bold">요청 승인 일시</small>
          <b-form class="mt-2" inline>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.approveDate.from" placeholder="2020-04-01 00:00:00" v-mask="'####-##-## ##:##:##'"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.approveDate.to" placeholder="2020-04-01 00:00:00" v-mask="'####-##-## ##:##:##'"></b-form-input>
            </b-form>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col lg="2">
          <small>충전 승인 금액</small><br/>
          <b-input-group>
            <b-form-input type="number" placeholder="최소가" v-model.number="form.approveAmount.min"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary"><i class="fa fa-exchange"></i></b-button>
            </b-input-group-append>
            <b-form-input type="number" placeholder="최대가" v-model.number="form.approveAmount.max"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="6" lg="4">
          <div><small class="mb-n2">승인 상태</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.request_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '입금 대기', value: 'pending'},
            {text: '승인 완료', value: 'approve'},
            {text: '취소 완료', value: 'cancel'},
            {text: '입금 실패', value: 'fail'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col cols="3" lg="3">
          <div><small class="mb-n2">승인 완료 타입</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.pay_type" :options="[
            {text: '전체', value: 'ALL'},
            {text: '환불(차감)', value: 'deduct'},
            {text: '충전 승인', value: 'charge'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col cols="3" lg="3">
          <div><small class="mb-n2">지급/차감 타입</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.charge_kind" :options="[
            {text: '전체', value: 'ALL'},
            {text: 'PRE_PAID(정상)', value: 'PRE_PAID'},
            {text: 'CREDITS(무료)', value: 'CREDITS'}
          ]"></b-form-radio-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="3" lg="3">
          <div><small class="mb-n2">정렬순서</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.order_by" :options="[
            {text: '정렬 없음', value: 'id desc'},
            {text: '높은 가격', value: 'credit_amount desc'},
            {text: '낮은 가격', value: 'credit_amount'},
          ]"></b-form-radio-group>
        </b-col>
        <b-col cols="1" lg="1">
          <div class=""><small>limit</small></div>
          <b-form-input id="limit" type="text" placeholder="한 번에 가져올 데이터 수" v-model.number="form.limit"></b-form-input>
        </b-col>
      </b-row>
      <b-button class="mr-1" variant="primary" @click="list" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
    </b-card>
    <div class="clearfix">
      <div v-if="$R('SKU_INHOUSE')">
        <div class="pull-left">
          <b-button v-if="$R('AD_W')" class="m-1" variant="danger" @click="selectCancel">선택항목 취소</b-button>
          <b-button v-if="$R('AD_W')" class="m-1" variant="danger" @click="showModal('DEDUCT')">차감하기</b-button>
          <b-button variant="outline-danger" class="m-1" :disabled="busy.xlsxUp" @click="_=>{$refs.xlsxUpload.value = null;$refs.xlsxUpload.click(), type = 'deduct'}">
            <b-spinner class="mr-2" small v-if="busy.xlsxUp"></b-spinner>
            일괄 차감하기
          </b-button>

          <b-button v-if="$R('AD_W')" class="m-1" variant="success" @click="showModal('CREDITS')">충전하기</b-button>
            <b-button variant="outline-success" class="m-1" :disabled="busy.xlsxUp" @click="_=>{$refs.xlsxUpload.value = null;$refs.xlsxUpload.click(),type = 'charge'}">
              <b-spinner class="mr-2" small v-if="busy.xlsxUp"></b-spinner>
              일괄 충전하기
            </b-button>
          <input type="file" ref="xlsxUpload" data-type="upload" style="display: none" @change="handleXlsx">


        </div>
        <div class="pull-right">
          <b-dropdown right text="일괄 차감/충전 Xlsx Down" variant="success" class="m-1">
            <b-dropdown-item @click="downBulk('deduct')">일괄 차감 양식</b-dropdown-item>
            <b-dropdown-item @click=" downBulk('charge')">일괄 충전 양식</b-dropdown-item>
          </b-dropdown>
          <b-button class="m-1 " variant="success" @click="down(items.registered, 'xlsx', 'Registered')">Xlsx Down</b-button>
        </div>
      </div>

    </div>
    <c-table :table-data="items.list" :fields="fields" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
             :caption="items.list.length + ' 개 데이터'" @get-more="list(true)"  @btn-clicked="btnAction">
    </c-table>
    <b-modal title="광고 크레딧 지급"
             v-model="modal.charge"
             size="xl">
      <div class="pb-2">
        <v-select v-if="paymentType !== 'PRE_PAID'" v-model="item.shop" :options="shop.filter(s=> walletMap[s.shop_id])" placeholder="SHOP을 선택해주세요" @input="selectShop"></v-select>
      </div>
      <div class="alert-info">
        <div class="p-3">
          {{ (item.shop || {}).label}} 회원에게 광고 크레딧을 지급합니다.
          지급 버튼을 누름과 동시에 크레딧이 지급되니 주의하세요!
        </div>
      </div>
      <b-row class="py-2">
        <b-col lg="4" class="pb-2">
          <b-row>
<!--            <b-col v-if="paymentType === 'PRE_PAID'">-->
<!--              <span>-->
<!--                <small class="mb-n2">입금액(원)</small><span class="ml-1 text-danger">*</span>-->
<!--                <b-form-input type="number" min="0" v-model="item.bank_amount"></b-form-input>-->
<!--              </span>-->
<!--            </b-col>-->
            <b-col>
              <span>
                <small class="mb-n2">충전희망금액(원)</small><span class="ml-1 text-danger">*</span>
                <b-form-input type="number" min="0" v-model="item.credit_amount"></b-form-input>
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="2" class="pb-2">
          <div><small class="mb-n2 col-form-label">지급 사유</small><span class="ml-1 text-danger">*</span></div>
          <b-form-radio-group  v-if="paymentType !== 'PRE_PAID'" class="col-form-label" v-model="item.charge_kind" :options="[
            {text: '정상 지급', value: 'PRE_PAID'},
            {text: '무료 지급', value: 'CREDITS'}, // 몰로코 기능 없음
          ]"></b-form-radio-group>
          <b class="col-form" v-if="paymentType === 'PRE_PAID'">정상 지급</b>
        </b-col>
        <b-col lg="5" class="pb-2">
          <div><small class="mb-n2">상세 사유</small></div>
          <b-form-input type="text" v-model="item.payment_memo"></b-form-input>
        </b-col>
      </b-row>
      <!--      <b-row class="pb-3">-->
      <!--        <b-col v-if="paymentType === 'CREDITS'" lg="4" class="pb-2">-->
      <!--          <div><small class="mb-n2">만료일</small></div>-->
      <!--          <b-form inline>-->
      <!--            <date-input v-model="item.expired_d"></date-input>-->
      <!--          </b-form>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <small>
        <div class="justify-content-start">
          <b>
            정상 충전금 잔액: {{$utils.comma(item.PRE_PAID || 0)}} 원 |
            무료 충전금 잔액: {{$utils.comma(item.CREDITS || 0)}} 원 |
            총 잔액: {{$utils.comma(item.remain_total || 0)}} 원
          </b>
        </div>
      </small>
      <template #modal-footer>
        <div class="pull-right">
          <b-button
              v-if="item.shop_id || item.shop"
              variant="light"
              class="mr-2"
              @click="modal.diff=true">
            수정이력
          </b-button>
          <b-button
              variant="secondary"
              class="mr-2"
              @click="modal.charge=false">
            취소
          </b-button>
          <b-button
              :disabled="!item.wallet_id || busy.recharging"
              variant="primary"
              @click="deductAndCharge">
            지급
            <b-spinner class="mr-1" small v-if="busy.recharging"></b-spinner>
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal title="광고 크레딧 차감"
             v-model="modal.deduct"
             size="xl">
      <div class="pb-2">
        <v-select v-if="paymentType !== 'PRE_PAID'" v-model="item.shop" :options="shop.filter(s=> walletMap[s.shop_id])" placeholder="SHOP을 선택해주세요" @input="selectShop"></v-select>
      </div>
      <div class="alert-info">
        <div class="p-3">
          {{ (item.shop || {}).label}} 회원에게 광고 크레딧을 차감합니다.
          차감 버튼을 누름과 동시에 크레딧이 차감되니 주의하세요!
          <br/>
          광고 소비가 실시간으로 일어나는 경우 실제 조회 시점 잔여금액과 다를 수 있으니 파트너가 캠페인 일 예산 설정을 0으로 해두었는지 확인해주세요.
<!--          잔여금액과 동일한 액수는 차감이 불가하며 잔여금액의 97%만 차감할 수 있습니다. (ex. 10,000원 잔여금액/ 전체 차감 원할 시 10000 * 0.97 = 9,700원 차감)-->
        </div>
      </div>
      <b-row class="py-3">
        <b-col lg="3" class="pb-2">
          <div><small class="mb-n2">차감 금액(원)</small><span class="ml-1 text-danger">*</span></div>
          <b-form-input type="number" min="0" v-model="item.credit_amount"></b-form-input>
        </b-col>
        <b-col lg="4" class="pb-2">
          <div><small class="mb-n2">차감 사유</small><span class="ml-1 text-danger">*</span></div>
          <b>정상 차감(환불)</b>
<!--            <b-form-radio-group class="col-form-label" v-model="item.charge_kind" :options="[-->
<!--              {text: '정상 차감(환불)', value: 'PRE_PAID'},-->
<!--              // {text: '무료 차감(환불)', value: 'CREDITS'}, // 몰로코 기능 없음-->
<!--            ]"></b-form-radio-group>-->
        </b-col>
        <b-col lg="5" class="pb-2">
          <div><small class="mb-n2">상세 사유</small></div>
          <b-form-input type="text" v-model="item.payment_memo"></b-form-input>
        </b-col>
      </b-row>
      <small>
        <div class="justify-content-start">
          <b>
            정상 충전금 잔액: {{$utils.comma(item.PRE_PAID || 0)}} 원 |
            무료 충전금 잔액: {{$utils.comma(item.CREDITS || 0)}} 원 |
            총 잔액: {{$utils.comma(item.remain_total || 0)}} 원
          </b>
        </div>
      </small>

      <template #modal-footer>
        <div class="pull-right">
          <b-button
              v-if="item.shop_id || item.shop"
              variant="light"
              class="mr-2"
              @click="modal.diff=true">
            수정이력
          </b-button>
          <b-button
              variant="secondary"
              class="mr-2"
              @click="modal.deduct=false">
            취소
          </b-button>
          <b-button
              :disabled="busy.recharging || !item.wallet_id"
              variant="primary"
              @click="deductAndCharge">
            차감
          </b-button>
        </div>
      </template>
    </b-modal>

<!--    ok-only-->
<!--    ok-title="닫기"-->
<!--    ok-variant="secondary"-->
    <b-modal :title="`${item.shop_id}. ${(shopMap[item.shop_id] || {}).boutique}`"
             v-model="modal.memo"
             ok-title="저장"
             cancel-title="닫기"
             @hide="saveMemo"
             size="xl">
      <div class="pb-2">
        <div><small class="mb-2">파트너 메모</small></div>
        <textarea class="form-control bg-white" disabled="disabled" style="min-height: 100px" wrap="soft" v-model="item.request_memo" placeholder="요청 메모"></textarea>
      </div>
      <div v-if="item.request_status !== 'cancel'">
        <div><small class="mb-2">지급/차감 사유</small></div>
        <textarea class="form-control bg-white" style="min-height: 100px" wrap="soft" v-model="item.payment_memo" placeholder="지급 / 차감 사유"></textarea>
      </div>
      <div v-if="item.request_status === 'cancel'">
        <div><small class="mb-2">취소 사유</small></div>
        <textarea class="form-control bg-white" disabled="disabled" style="min-height: 100px" wrap="soft" v-model="item.cancel_memo" placeholder="취소 사유"></textarea>
      </div>
    </b-modal>
    <b-modal title="수정이력 확인"
             v-model="modal.diff"
             ok-only
             ok-title="닫기"
             ok-variant="secondary">
      <div>
        <div v-for="diff in item.diff" class="border-bottom py-1">
          <div class="p-2">
            <span>
              <b class="pr-3">{{diff.approve_by || diff.biz_name}}</b>
              <span class="pr-3"><span class="badge alert-dark">변경됨</span></span>
              <span class="pr-3">{{$utils.comma(diff.credit_amount)}}</span>
              <span class="pr-3">{{TYPE_MAP[diff.pay_type]}}</span>
             <span class="pr-3 pull-right"><span class="badge alert-secondary">{{diff.approve_dt}}</span></span>
            </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as momentBiz from 'moment-business-days';
import {down, readXlsx} from "@/shared/impexp";

const STATUS_MAP = {
  'pending': '<span class="badge alert-dark">입금 대기</span>',
  'approve': '<span class="badge alert-primary">승인 완료</span>',
  'cancel': '<span class="badge alert-warning">취소 완료</span>',
  'fail': '<span class="badge alert-danger">입금 실패</span>',
}

export default {
  name: 'AdList',
  title: '광고 리스트/충전',
  data() {
    return {
      TYPE_MAP: {
        'deduct': '차감',
        'charge': '지급'
      },
      type: '',
      form: {
        search: '',
        shop: [],
        order_by: 'id desc',
        request_status: 'pending',
        charge_kind: 'ALL',
        pay_type: 'ALL',
        limit: 200,
        skip: 0,
        requestDate: {
          from: '',
          to: ''
        },
        deadline: {
          from: '',
          to: ''
        },
        approveDate: {
          from: '',
          to: ''
        },
        approveAmount: {
          min: null,
          max: null
        },
      },
      shop: [],
      walletMap: {},
      shopMap: {},
      collapse: {detail:false},
      lastBody: {list:{}},
      items: {list:[]},
      busy: {list: false, recharging: false, xlsxUp: false, saveMemo: false},
      hasMore: {list:false},
      ac: {list: null}, // abortController
      defaultItemForm: {
        id: null,
        shop_id: null,
        shop: null,
        request_status: 'approve',
        biz_name: null,
        charge_kind: '',
        pay_type: 'charge',
        payment_memo: '',
        credit_amount: null,
        // bank_amount: null,
        expired_d: '',
        expired_t: '00:00:00',
        // expired_d: this.$moment().format('YYYY-MM-DD'),
        // expired_t: '00:00:00',
      },
      item: {},
      item_org: {},
      perPage: 20,
      fields: [
        'selected',
        {key: 'html8', class: 'text-left', label:`요청 번호`},
        {key: 'html1', class: 'text-left', label:`<span class="badge alert-success">파트너번호</span><br/>사업자 이름`},
        {key: 'html10', label:'입금필요금액', class: 'text-center'},
        {key: 'html2', label:'충전희망금액', class: 'text-center'},
        {key: 'html3', label:'충전 승인 금액', class: 'text-center'},
        {key: 'html4', label:'환불(차감)액', class: 'text-center'},
        {key: 'html5', label:'요청 일시<br/>입금 마감 일시', class: 'text-center'},
        {key: 'html6', label:'승인자<br/>요청 승인 일시', class: 'text-center'},
        {key: 'html7', label:'<div title="PRE_PAID = 정상\nCREDIT = 무료" id="charge_kind">지급/차감 타입<span class="fa fa-question-circle pl-1"></span></div>승인 상태', class: 'text-center'},
        {key: 'html9', label:'요청 건의', class: 'text-center'},
        {key:'_actions', label:'관리', style:{maxWidth:'200px', textAlign:'center'}, buttons: [
            {label:'취소', event:'cancel', variant: 'danger', if: e => e.request_status === 'pending'},
            {label:'충전하기', event:'charge',  variant: 'success', if: e => e.request_status === 'pending'},
            {label:'상세 메모', event:'show_memo'}
          ]},
      ],
      modal: {charge: false, deduct: false, memo: false, diff: false, cancel: false},
      paymentType: '',
      priceHtml: '',
      today: momentBiz().format('YYYY-MM-DD'),
    }
  },
  async created() {
    this.item = this.$utils.clone(this.defaultItemForm);
    let meta = await this.$api.getMeta('shop');
    await this.walletList();

    this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    this.shop.forEach(s => {
      s.value = s.shop_id;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });
    await this.list();
  },
  methods: {
    async list(more) {
      const body = {...this.form};
      if (body.shop && body.shop.length) {
        body.shop = body.shop.map(s => s.shop_id);
      }

      const j = await this.$api.postTable(this, '/ad/list', body, {more, fnAssign: this.assignTableData});
      if (j) {
        this.totalCnt = j.totalCnt;
      }
    },
    assignTableData(e) {
      e.boutique = this.shopMap[e.shop_id].boutique;
      e.html8 = e.id;
      e.html1 = `<span class="badge alert-success">${e.shop_id}. ${e.boutique}</span><br/>${e.biz_name}`;
      e.html10 = e.deposit_amount ? `${this.$utils.comma(e.deposit_amount)} 원` : '-';
      e.html2 = e.request_amount ? `${this.$utils.comma(e.request_amount)} 원` : '-';
      e.html3 = ((e.credit_amount && e.credit_amount > 0) ? `${this.$utils.comma(e.credit_amount)} 원` : '-');
      e.html4 = e.credit_amount && e.credit_amount < 0 ? `${this.$utils.comma(e.credit_amount)} 원` : '-';

      [e.created_t] = e.created_dt.split(' ').slice(-1);
      e.html5 = `${e.request_amount ? e.created_dt : '-'}<br/>${e.request_amount ? `${momentBiz(e.created_dt).businessAdd(2, 'day').format('YYYY-MM-DD')} ${e.created_t}` : '-'}`;
      e.html7 = `<small><b>${e.charge_kind}</b></small><br/>${STATUS_MAP[e.request_status]}`;
      e.html9 = `<div class="text-truncate" style="width:100px;" title="${e.request_memo || ''}">${e.request_memo || ''}</div>`;
      e.html6 = `${e.approve_by}<br/>${e.approve_dt || ''}`;
    },
    async walletList() {
      const j = await this.$api.getJson('/ad/wallets');
      if (j && j.ok) {
        this.walletMap = j.walletMap;
      }
    },
    async showModal(paymentType) {
      this.item = this.$utils.clone(this.defaultItemForm);
      this.paymentType = '';
      const selectedMap = {};
      const selected = this.items.list.filter(item => item.selected);
      selected.forEach(item => selectedMap[item.shop_id] = true);
      if (Object.keys(selectedMap).length) {
        if (Object.keys(selectedMap).length > 1) return alert('하나의 샵만 선택해주세요.');
        this.item.shop = this.shopMap[selected[0].shop_id];
        this.selectShop(this.item.shop);
      }

      this.paymentType = paymentType;
      if (paymentType === 'DEDUCT') {
        this.modal.deduct = true;
      } else {
        this.modal.charge = true;
      }
    },
    async selectCancel() {
      const ids = this.items.list.filter(item => item.selected).map(item => item.id);
      if (!ids.length) {
        return alert('취소할 요청을 선택해주세요.');
      }
      await this.cancel(ids);
    },
    async cancel(items) {
      if (!confirm('취소를 진행하시겠습니까?')) return;
      let cancel_memo =  prompt('취소 사유를 입력해주세요.') || "";
      if (!cancel_memo) return;
      const j = await this.$api.postJson('/ad/cancel', {ids: items.map(item=> item.id), cancel_memo});
      if (j && j.msg) {
        alert(j.msg);
        this.list();
      }
    },
    async deductAndCharge(e) {
      this.busy.recharging = true;
      if (this.paymentType !== 'DEDUCT' && !this.item.charge_kind) {
        this.item.charge_kind = this.paymentType;
      } else if (this.paymentType === 'DEDUCT') {
        this.item.charge_kind = 'PRE_PAID';
      }

      if (!this.item.credit_amount) {
        alert(`${this.paymentType !== 'DEDUCT' ? '지급' : '차감'} 금액을 입력해주세요`);
        e.preventDefault && e.preventDefault();
        this.busy.recharging = false;
        return;
      }

      if (!(+this.item.credit_amount <= 50000000 && +this.item.credit_amount > 0)) {
        alert(`입력가능한 금액이 아닙니다. (0 초과, 50,000,000이하 만 입력 가능)`);
        e.preventDefault && e.preventDefault();
        this.busy.recharging = false;
        return;
      }

      // if (!this.item.bank_amount && this.paymentType === 'PRE_PAID') {
      //   alert(`파트너사 입금액을 입력해주세요`);
      //   e.preventDefault && e.preventDefault();
      //   this.busy.recharging = false;
      //   return;
      // }
      if (!this.item.shop_id && !this.item.shop) {
        alert('샵을 선택해주세요');
        e.preventDefault && e.preventDefault();
        this.busy.recharging = false;
        return;
      }
      if (this.item.shop) {
        this.item.shop_id = this.item.shop.shop_id;
      }
      if (!this.item.charge_kind) {
        alert(`${this.paymentType !== 'DEDUCT' ? '지급' : '차감'} 사유를 선택해주세요`);
        e.preventDefault && e.preventDefault();
        this.busy.recharging = false;
        return;
      }
      if (this.item.expired_d === '____-__-__') {
        this.item.expired_d = '';
      }
      if (this.item.expired_d  && !this.item.expired_t) {
        this.item.expired_t = '00:00:00';
      }

      if (this.item.expired_d && this.item.expired_t) {
        this.item.expired_dt = `${this.item.expired_d} ${this.item.expired_t}`;
        if (!/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(this.item.expired_dt)) {
          alert(`만료일이 잘못된 형식으로 입력되었습니다.`);
          e.preventDefault && e.preventDefault();
          this.busy.recharging = false;
          return;
        }
      }
      this.item.credit_amount = +this.item.credit_amount;
      // this.item.bank_amount = +this.item.bank_amount;
      this.item.biz_name = this.shopMap[this.item.shop_id].biz_name || this.shopMap[this.item.shop_id].boutique;

      // if (this.paymentType === 'PRE_PAID' && this.item.bank_amount !== this.item.credit_amount) {
      //   if(!confirm('입금액과 지급액이 다릅니다. 충전을 진행하시겠습니까?')) {
      //     this.busy.recharging = false;
      //     return;
      //   }
      // }
      let j;
      if (this.paymentType === 'DEDUCT') {
        this.item.pay_type = 'deduct';
        if (this.item.credit_amount > this.item[this.item.charge_kind]) {
          alert(`차감액이 잔여 금액을 초과하였습니다.`);
          this.busy.recharging = false;
          return;
        }
        this.item.credit_amount *= -1
        j = await this.$api.postJson('/ad/deduct', {item: this.item});
      } else {
        j = await this.$api.postJson('/ad/charge', {item: this.item});
      }

      if (j && j.ok) {
        alert(`${this.paymentType === 'DEDUCT' ? '차감' : '지급'}에 성공하였습니다.`);
      }
      this.busy.recharging = false;
      this.modal.deduct = false;
      this.modal.charge = false;
      await this.list();
    },
    async selectShop(s) {
      if (s) {
        await this.getAccountInfo(s.shop_id, this.paymentType === 'DEDUCT');
        const j = await this.$api.postJson('/ad/list', {shop: [s.shop_id], request_status: 'approve'});
        if (j && j.ok) {
          this.item.diff = j.list;
        }
      }
    },
    async getAccountInfo(shop_id, getCampaign) {
      const j = await this.$api.getJson(`/ad/accountInfo?shop_id=${shop_id}&getCampaign=${getCampaign}`);
      if (j && j.ok) {
        Object.assign(this.item, j.stat);
        this.$forceUpdate();
      }
    },
    async btnAction(row, event) {
      const item = row.item
      if (event === 'charge') {
        this.item = this.$utils.clone(this.defaultItemForm);
        this.item.shop_id = item.shop_id;
        this.item.id = item.id;
        this.item.credit_amount = item.request_amount;
        // this.item.bank_amount = item.request_amount;
        this.paymentType = 'PRE_PAID';
        this.selectShop(item);

        this.modal.charge = true;
      } else if (event === 'cancel') {
        await this.cancel([item]);
      } else if(event === 'show_memo') {
        this.item = this.$utils.clone(item);
        this.item_org = this.$utils.clone(item);
        this.modal.memo = true;
      }
    },
    async saveMemo(event) {
      this.busy.saveMemo = true;
      if (this.item.payment_memo !== this.item_org.payment_memo) {
        if (event.trigger === 'ok') {
          await this.$api.postJson('/ad/saveMemo', {
            id: this.item.id, payment_memo: this.item.payment_memo
          });
          this.list();
        } else {
          if (!confirm('변경사항을 저장하지 않으시겠습니가?')) {
            event.preventDefault && event.preventDefault();
          }
        }
      }
      this.busy.saveMemo = false;
    },
    async downBulk(type) {
      let baseFields = '';
      let baseHeaders = '';
      let filename = ''

      if (type === 'deduct') {
        baseHeaders = 'SHOP ID,차감 금액(원),상세 사유';
        baseFields = 'shop_id,credit_amount,payment_memo';
      } else {
        baseHeaders = 'SHOP ID,지급 금액(원),지급 사유(PRE_PAID/CREDITS 중 선택하여 입력),상세 사유';
        baseFields = 'shop_id,credit_amount,charge_kind,payment_memo,memo';
      }
      filename = `AD_${type}_${this.$utils.dt()}`;

      down([], baseHeaders.split(','),
          baseFields.split(','), filename, 'xlsx');
    },
    async down() {
      let items = this.items.list.filter(e => e.selected);
      if (!items.length) {
        return alert('다운 받을 데이터를 선택해주세요.');
      }
      let baseFields = '요청 번호,ShopID,Shop,입금필요금액,충전희망금액,충전 승인 금액,환불(차감)액,요청 일시,입금 마감 일시,승인자,요청 승인 일시,지금/차감 타입,승인 상태,요청 건의,상세 메모';

      const REQUEST_STATUS_NAME = {
        'pending': '입금 대기',
        'approve': '승인 완료',
        'cancel': '취소 완료',
        'fail': '입금 실패',
      }
      items.forEach(item => {
        if (item.pay_type === 'deduct') {
          item.deduct_amount = item.credit_amount;
        } else {
          item.charge_amount = item.credit_amount;
        }
        item.request_status_name = REQUEST_STATUS_NAME[item.request_status] || ''
      })
      down(items, baseFields.split(','),
          ('id,shop_id,boutique,deposit_amount,request_amount,charge_amount,deduct_amount,created_dt,expired_dt,approve_by,approve_dt,charge_kind,request_status_name,request_memo,payment_memo').split(',')
          , `AD_${this.$utils.dt()}`, 'xlsx');
    },
    async handleXlsx(event) {
      this.xlsxUp = true;
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');
      const {rows, headers} = await readXlsx(file);

      let colMap = {}
      if (this.type === 'deduct') {
        colMap = {
          'SHOP ID': {code: 'shop_id', type: 'number', require: true},
          '차감 금액(원)': {code: 'credit_amount', type: 'number', require: true},
          '상세 사유': {code: 'payment_memo', type: 'string'}
        };
      } else {
        colMap = {
          'SHOP ID': {code: 'shop_id', type: 'number', require: true},
          '지급 금액(원)': {code: 'credit_amount', type: 'number', require: true},
          '지급 사유(PRE_PAID/CREDITS 중 선택하여 입력)': {code: 'charge_kind', type: 'string', require: true},
          '상세 사유': {code: 'payment_memo', type: 'string'}
        }
      }

      await this.uploadXlsx(rows, headers, colMap);
      this.xlsxUp = false;
      this.list();
    },
    async uploadXlsx(rows, headers, colMap) {
      let unknown = headers.filter(e=>!colMap[e]);
      if (unknown.length) return alert('알 수 없는 컬럼들이 있습니다:\n'+unknown.join('\n'));
      let required = Object.entries(colMap).filter(([k, v]) => v.require).filter(([k, v]) => !~headers.includes(k))
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n'+required.join('\n'));

      const items = [];
      const wrongItems = [];
      const noRequireItems = [];
      rows.forEach(row=>{
        const item = this.$utils.clone(this.defaultItemForm);
        let shop = this.shopMap[item.shop_id];

        headers.forEach(h=>{
          let type = colMap[h].type;
          if (type === 'number') {
            row[h] = +row[h];
          } else if (type === 'string') {
            row[h] = row[h] == null ? null : '' + row[h];
          }
          item[colMap[h].code] = row[h];
          if (colMap[h].require && !item[colMap[h].code]) {
            noRequireItems.push(item);
            return;
          }
        });

        item.biz_name = shop ? (this.shopMap[item.shop_id].biz_name || this.shopMap[item.shop_id].boutique) : '';
        item.shop = shop ? this.shopMap[item.shop_id].boutique : '';
        item.pay_type = this.type;
        if (this.type === 'deduct') {
          item.charge_kind = 'PRE_PAID'
        }

        if (!(+item.credit_amount <= 50000000 && +item.credit_amount > 0) || !item.charge_kind) {
          wrongItems.push(item);
          return;
        }

        items.push(item);
      });
      if (noRequireItems.length) return alert(`필수 항목을 입력 후 재 업로드 해주세요.`);
      if (wrongItems.length) return alert(`잘못된 데이터가 존재합니다.`);

      const j = await this.$api.postJson('/ad/uploadXlsx', {items, type: this.type});

      if (j.ok === 1) {
        this.$utils.alert(`${j.successCnt}개의 지급/차감 작업이 완료되었습니다.
         ${j.errors && j.errors.length ? `<br/><br/>일부 작업에서 다음 에러가 발생했습니다.<br/>${j.errors.map(e=>`${e.item.shop_id}. ${this.shopMap[e.item.shop_id].boutique}: ${e.msg}`).join('<br/>')}` : ''}`);
      }
    }
  }
}
</script>
